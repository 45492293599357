<template>
  <div>
    <div class="md-modal md-effect-11 md-show">
      <div class="md-content">
        <h3 class="bg-dark">
          {{ $("payment.payment_details") }} -
          <span class="f-16 d-inline d-sm-none">{{ payment.payment_id }}</span>
          <span class="d-none d-sm-inline">{{ payment.payment_id }}</span>
        </h3>
        <div class="card-body p-0">
          <table class="table table-striped table-hover">
            <tbody>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $("common.operator") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ operatorLabel }}
                </td>
              </tr>
              <tr v-for="detail in payment.details" :key="detail.id">
                <td class="align-middle text-right">
                  <i>
                    {{ detail.property.label }}
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ detail.valeur }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $("common.fees") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ (payment.fee_amount || 0) + " " + payment.currency }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $("common.amount") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ payment.amount + " " + payment.currency }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i class="d-inline d-sm-none">{{ $("common.date") }}</i>
                  <i class="d-none d-sm-inline">
                    {{ $("common.creation_date") }}
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ payment.created_at | moment("DD MMMM YYYY") }}
                  <small class="d-block">
                    <i class="fa fa-clock f-w-300"></i>
                    {{ payment.created_at | moment("H:m:s") }}
                  </small>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $("common.status") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  <i :class="statusClassIcon"></i> {{ payment.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="card-footer text-center">
            <button
              class="btn btn-primary"
              role="button"
              type="button"
              @click.prevent="close"
            >
              {{ $("common.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="md-overlay" @click.prevent="close"></div>
  </div>
</template>

<script>
import { paymentMixin } from "../../mixins/paymentMixin";

export default {
  name: "PaymentDetails",
  mixins: [paymentMixin],
  methods: {
    close() {
      this.$router.back();
    },
  },
};
</script>

<style scoped></style>
