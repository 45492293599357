export const paymentMixin = {
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    activeClass() {
      switch (this.payment.status) {
        case "Succès":
          return "success";
        case "Echec":
          return "danger";
        case "En cours":
          return "info";
      }
    },
    statusClassIcon() {
      switch (this.payment.status) {
        case "Succès":
          return "fas fa-thumbs-up text-success";
        case "Echec":
          return "fas fa-thumbs-down text-danger";
        case "En cours":
          return "fas fa-hourglass-half text-info";
      }
    },
    operatorLogo() {
      return this.payment.operator
        ? this.payment.operator.logo
        : "https://api.be-wallet.net/storage/uploads/operators/BywqP1wPsxhw2WpctEiEnTyoXyyZIutxf5dhF5b2.png";
    },
    operatorLabel() {
      return `${this.payment.operator.label} ${
        this.payment.operator.country ? this.payment.operator.country.name : ""
      }`;
    },
  },
};
